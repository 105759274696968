/* home */
#home{
    background: rgb(139, 0, 219);
    background: linear-gradient(90deg, rgb(95, 0, 219) 50%, rgb(225, 0, 255) 100%);
    min-height: 300px;
    
}
.navbar{
    top: 0 !important;
    position: fixed !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 800 !important;
}

.footer1 p{
    font-size: 14px !important;
}
    
 /* chatbot styling */

.godhbL{
bottom: 90px !important;
right: 16px !important;
}

a.nav-link:hover{
    color: #2691d9;
}
a.nav-link{
  color: rgb(1, 1, 1);
}
a.nav-link.active{
    color: #2691d9 !important;
}
.nav-item{
    padding-left: 12px;
    padding-right: 12px;
}

.paragraph{
    line-height: 25px;
}

.carousel-control-next-icon, .carousel-control-prev-icon{
color: white !important;
font-size: 15px !important;
}

.carousel-caption {
   
    bottom: 17% !important;
    width: 50% !important;
    left: 23% !important;
}

.rightbox{
    float:right !important;
}

.navbar-nav{
    margin-left: auto !important;
}

/* card */
/*  */
.card{
    transition: all 0.5s ease;
}

.card:hover{
    cursor: pointer;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

/* form  */

.form{
    background: rgb(0, 101, 219);
    background: linear-gradient(90deg, rgb(0, 101, 219) 40%, rgb(0, 212, 255) 100%);
}

/* image  */

.image{
    height: 60px;
}
.font{
    font-size: 1.8rem;
    font-weight: bolder;
}
.span{
    background: linear-gradient(90deg, rgb(0, 101, 219) 40%, rgb(0, 212, 255) 100%);
    -webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    font-size: 1.8rem;
    line-height: 40px;
    font-weight: 600;
}

.cardtitle{
    background: linear-gradient(90deg, rgb(0, 101, 219) 40%, rgb(0, 212, 255) 100%);
    -webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    font-size: 25px;
}

/* carousal */
.carousalheight{
    max-height: 80vh;
 
 
}
.pp-5 {
    padding-top: 3rem!important;
    padding-bottom:0.1rem!important;

}

.mp-5 {
    margin-top: 3rem!important;
    margin-bottom: 0.1rem!important;
}

/* footer  */

.footer{
    background: rgb(0, 101, 219);
    background: linear-gradient(90deg, rgb(0, 101, 219) 40%, rgb(0, 212, 255) 100%) !important ;
}


.footer1{
    background: rgb(131, 0, 219);
    background: linear-gradient(90deg, rgb(0, 101, 219) 40%, rgb(0, 212, 255) 100%) !important ;
}


.navbar-nav{
    align-content: flex-end !important;
    flex-wrap: wrap !important;
    align-items: center !important;

}






@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* Reseting */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
/* 
body{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.container{
    transform-style: preserve-3d;
}

#services{
    --e-bg-lazyload: url(https://zedexinfo.com/wp-content/uploads/2022/12/Slice-1.png);

}

.container .box1{
    position: relative;
    width: 300px;
    height: 300px;
    margin: 20px;
    transform-style: preserve-3d;
    perspective: 1000px;
    cursor: pointer;
}

.container .box1 .body1{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 0.9s ease;
}



.container .box1 .body1 .imgContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    box-shadow: 2px 20px 20px 4px rgba(22, 22, 22, 0.6);
    border-radius: 20px;

}

.container .box1 .body1 .imgContainer img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container .box1 .body1 .content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #333;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: rotateY(180deg);
}

.container .box1:hover .body1{
    transform: rotateY(180deg);
}

.container .box1 .body1 .content div{
    transform-style: preserve-3d;
    padding: 20px;
    border-radius: 20px;
    background: linear-gradient(45deg, #FE0061,#FFEB3B);
    transform: translateZ(100px);
}

.container .box1 .body1 .content div h3{
    letter-spacing: 1px;
}

  .container{
    max-width: 1400px !important;
  }


  html {
    scroll-behavior: smooth;
  }

 #progress {
    position: fixed;
    bottom: 20px;
    right: 10px;
    height: 60px;
    width: 60px;
    display: none;
    place-items: center;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  #progress-value {
    display: block;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    background-color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 35px;
    color: #001a2e;
  }
  


  /* services styling */

  p,a,h1,h2,h3,h4,h5,h6,ul,li {
	margin: 0;
	padding: 0;
}

.section-services {
	font-family: "Poppins", sans-serif;
	color: #202020;
	padding-top: 115px;
    padding-bottom: 120px;
}

.hr{
    color:white !important;
    opacity: 1 !important;
}

.section-services .header-section {
	margin-bottom: 34px;
  
}
  .container{
    width: 90%;
     margin: auto;
    
 

  }
.section-services .header-section .title {
	position: relative;
    padding-bottom: 14px;
    margin-bottom: 25px;
	font-weight: 700;
    font-size: 32px;
    

}



.section-services .header-section .description {
	font-size: 14px;
    color: #282828;
}

.section-services .single-service {
    position: relative;
    margin-top: 30px;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 30px;
    overflow: hidden;
    box-shadow: 2px 1px 10px 1px rgb(22 22 22 / 60%);

}

.section-services .single-service .content {
	position: relative;
	z-index: 20;

}

.section-services .single-service .circle-before {
    position: absolute;
    top: 0;
    right: 0px;
    transform: translate(40%, -40%);
    width: 150px;
    height: 150px;
    background: linear-gradient(90deg, rgb(0, 101, 219) 40%, rgb(0, 212, 255) 100%);

    border: 6px solid #504f93;
    border-radius: 50%;
    opacity: 0.5;
    z-index: 10;
    transition: all .6s;
}

.section-services .single-service:hover .circle-before {
	width: 100%;
	height: 100%;
	transform: none;
	border: 0;
	border-radius: 0;
	opacity: 1;
}

.section-services .single-service .icon {
	display: inline-block;
	margin-bottom: 26px;
    width: 70px;
    height: 70px;
    /* background-color: #ff4500; */
    border-radius: 5px;
    line-height: 70px;
    text-align: center;
    font-size: 60px;
    transition: all .3s;
}

.section-services .single-service:hover .icon {
	/* color: #ff4500; */
    color: white;
}

.section-services .single-service .title {
    margin-bottom: 18px;
	font-weight: 700;
    font-size: 23px;
    transition: color .3s;
}

.section-services .single-service:hover .title {
	color: #fff;
}

.section-services .single-service .description {
    margin-bottom: 20px;
    font-size: 14px;
    transition: color .3s;
}

.section-services .single-service:hover .description {
	color: #fff;
}

.section-services .single-service a {
	position: relative;
	font-size: 18px;
    color: #202020;
    text-decoration: none;
    font-weight: 500;
    transition: color .3s;
}

.section-services .single-service:hover a {
	color: #fff;
}

.section-services .single-service a:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 1px;
	background-color: #0e0d0d;
	transition: background-color .3s;
}

.section-services .single-service:hover a:after {
	background-color: #fff;
}

@media (min-width: 768px){
.col-lg-4 {
    flex: 0 0 auto;
    width: 100.333333% !important;
}

}

@media (max-width:770px){
    .col-md-5{
        width: 98% !important;
        margin: auto !important;
        border-radius: 10px !important;
        padding: 12px !important;
    }
}

/* @media (max-width: 1000px){

.container{
  
}

} */

/* iframe code  */

.iframe{
    height: 350px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 2px 1px 10px 4px rgba(0, 0, 0, 0.2);
}


@media (max-width: 768px){
    body{
        font-size: 17px !important;
    }
    .carousel-caption{
       bottom: 0% !important;
        width: 100% !important;
        left: 1% !important ;
    }
 
    .mp-5{
        margin-top: 0rem !important;

    }
    .pp-5{
        padding-top: 0rem !important;
    }

}


@media (max-width: 500px){
    .font{
        font-size: 1.3rem !important;
    }

    .span{
    
        font-size: 1.3rem;
        line-height: 10px;
        font-weight: 600;
    }
    .paragraph{
        font-size: 0.7rem !important;
        line-height: 20px;
    }

    .carousel-caption {
   
        bottom: 1% !important;
        width: 70% !important;
        left: 16% !important;
    }

   h3.fs-5.mb-0{
        font-size: 1.4rem !important;
    }
    .display-6{
        font-size: 1.4rem !important;
    }
    .lead{
        font-size: 1.1rem !important;
    }
    .btn{
        font-size: 0.8rem !important;
    }
    .cardtitle{
        font-size: 1.4rem !important;
    }
    .fa-2x{
        font-size: 1.5rem !important;
    }
}

.my-5{
    margin-top: 8rem !important;
} 



/* @media (max-width: 992px){
.navbar-collapse{
position: absolute !important;
top: 100% !important;
right: -100% !important;
width: 170px !important;
height: 60vh !important;
background: rgb(12, 11, 11) !important;
display: flex !important;
flex-direction: column !important;
justify-content: flex-start !important;
border-radius: 3px !important;
}
} */


.cPycKc {
    height: 92px !important;
    width: 92px !important;
}

.cRmLCo{
    background: #b94fff !important ;
}

.godhbL{
    background: white !important;

}
.glfuN{
    background: #b94fff !important ;
  }
  .hQsUiY{
    background: #b94fff !important ;

  }

  .ipSaXD {
    background: #b94fff !important ;
  }

  .lifvqk {
    margin: 0;
    font-weight: bolder !important;
    font-size: 22px !important;
}
  .dropdown-menu {
    --bs-dropdown-link-active-bg: none !important;
  }


  .section-team {
    padding: 50px 0;
  }
  
  .single-member {
    background: #f9f9f9;
    padding: 20px;
    margin-bottom: 30px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .single-member .member-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .single-member .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .single-member .role {
    font-size: 10px;
    color: #888;
    margin-bottom: 10px;
  }
  

  .section-team {
    padding: 70px 0;
  }
  
  .header-section h2 {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .header-section p.lead {
    font-size: 1.125rem;
    color: #090dca;
  }


.team-member-card {
  display: flex; /* Enables flexbox layout */
  flex-direction: column; /* Aligns items in a column direction */
  align-items: center; /* Center-aligns items horizontally */
  text-align: center; /* Center-aligns text */
  border: 1px solid #ddd; /* Adds a border around the card */
  border-radius: 8px; /* Optional: Adds rounded corners */
  padding: 20px; /* Adds padding inside the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
  background-color: #fff; /* Sets background color */
}

.team-photo {
  width: 100%;
  max-width: 150px; /* Set a maximum width for the image */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the area without distortion */
  border-radius: 50%; /* Optional: makes the image circular */
  margin-bottom: 15px; /* Adds space below the image */
}

.card-body {
  padding: 10px; /* Adjust padding around the text */
}

.title {
  font-size: 1.25rem; /* Adjust the font size as needed */
  font-weight: bold; /* Makes the title bold */
  margin-bottom: 10px; /* Adds space below the title */
}

.role {
  font-size: 1rem; /* Adjust the font size as needed */
  color: #555; /* Sets a color for the role text */
  margin-bottom: 10px; /* Adds space below the role */
}

.description {
  font-size: 0.875rem; /* Adjust the font size as needed */
  color: #777; /* Sets a color for the description text */
  margin-bottom: 15px; /* Adds space below the description */
}

.profile-link {
  color: #007bff; /* Sets the color of the link */
  text-decoration: none; /* Removes underline from the link */
  font-weight: bold; /* Makes the link text bold */
}
  

  /* @media (max-width: 570px){
    .glfuN{
        margin-top: 5.4rem !important;
    }

    .irhLDX{
        height: 670px !important;
        margin-bottom: -5.4rem !important;
        overflow-y: scroll !important;
    }
  } */

  .container {
    max-width: 1200px;
    margin: auto;
    padding: 0.1rem;
  }
  
  .section-profile {
    text-align: center;
  }
  
  .profile-card {
    background: #f7f7f7;
    padding: 2rem;
    border-radius: 8px;
  }
  
  .profile-photo {
    width: 200px;
    height: auto;
    border-radius: 50%;
    margin-bottom: 1.5rem;
  }
  
  .profile-body {
    text-align: left;
  }
  
  .profile-body h3 {
    margin-top: 1.5rem;
  }
  
  .profile-body ul {
    list-style-type: none;
    padding: 0;
  }
  
  .profile-body ul li {
    margin-bottom: 0.5rem;
  }
  
